// layout


/* Page container to handle the flex layout */
.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    /* Ensure the container fills the entire viewport */
}

/* Content area to take up remaining space */
.content-wrap {
    flex-grow: 1;
    /* This will take up any extra space available */
}

// spinner

.login-spinner {
    text-align: center;
    padding-top: 260px;
}

// cc table css

.cc-row {
    font-size: 13px;
}

.cc-total {
    font-family: $font-bold;
    font-size: 13px;
}

table thead th {
    font-weight: normal !important;
}

.cc-header {
    font-family: $font-regular;
    font-size: 13px;
    font-weight: normal !important;
}

@media (min-width: 900px) {
    .hide-md {
        display: none !important;
    }
}

/* Hide the columns contributing to the 'Other' sum on XS screens */
@media (max-width: 899px) {
    .hide-xs {
        display: none !important;
    }

    .hide-md {
        display: table-cell !important;
    }

    .show-xs {
        display: table-cell !important;
    }
}

//Background colours
.bgcol-secondary {
    background-color: $secondary !important;
    // background: linear-gradient(180deg, #008080, #006f72, rgb(0, 57, 65), #00050a);
}

//Colours

.accent-6 {
    color: $white !important;
}

//Headings
.hero-text-1 {
    font-size: $hero-font-1-xl;
    font-family: $font-bold;
}


.hero-text-2 {
    font-size: $hero-font-2-xl;
    font-family: $font-bold;
}

.hero-text-3 {
    font-size: $hero-font-3-xl;
    font-family: $font-medium;
}

.hero-text-4 {
    font-size: $hero-font-4-xl;
    font-family: $font-medium;
}

.blog-heading {
    font-family: "Ubuntu-Medium";
    font-size: 28px;
}

.header-brush {
    color: #fd9d00 !important;
}

.header-brush-teal {
    color: #003333 !important;
    font-size: 55px;
}

.hero-text-2-emphasis {
    color: #003333;
}

//Body text
.standard-text {
    font-size: $standard-text-xl;
    font-family: $font-regular;
    color: $neutral-1;
}

.large-text {
    font-size: $large-text-xl;
    font-family: $font-regular;
    color: $neutral-1;
}



.content-body {
    font-family: $font-regular;
    font-size: 20px;
    color: $gradiant;
}

.text404 {
    font-size: 52px;
}

// Font widths
.text-wide {
    letter-spacing: 1px;
}

.text-medium {
    letter-spacing: 0.5px;
}

.form-control {
    max-height: 175px;
}

//Font colours
.primary-text {
    color: $primary;
}

.secondary-text {
    color: $secondary;
}

.tertiary-text {
    color: $tertiary;
}

.accent-1-text {
    color: $accent-1;
}

.accent-2-text {
    color: $accent-2;
}

.accent-3-text {
    color: $accent-3;
}

.accent-4-text {
    color: $accent-4;
}

.accent-5-text {
    color: $accent-5;
}

.neutral-1-text {
    color: $neutral-1;
}

.neutral-2-text {
    color: $neutral-2;
}

.neutral-3-text {
    color: $neutral-3;
}

.white-text {
    color: $white;
}

// Layout

.container-large {
    width: 100%;
    padding-left: 150px;
    padding-right: 150px;
    padding-bottom: 0px;
}

.heading-block {
    padding: 50px 0px 25px 0px;
}

.content-block {
    padding: 75px 0px 75px 0px;
}

// Cursor styles
.pointer {
    cursor: pointer;
}

// Logo icons and images
.logo-rect {
    width: 50%;
}

.logo-square {
    width: 20%;
}



.icon-large {
    height: 175px;
    width: 175px;
}


// Button styles
.cta-button,
.submit-cta-button {
    background-color: $accent-1;
    color: $neutral-1;
    border-radius: $border-radius;
    cursor: pointer;
    font-family: $font-regular;
    padding: 0.7rem 1.5rem;
    font-size: 1.1rem;
    border: 0px;

    &:hover {
        background-color: darken($accent-1, 2%);
        transform: scale(1.03);
    }
}

.button-white {
    background-color: $white !important;
    color: $neutral-1 !important;
    border: 0px !important;
}

.contact-cta-button {
    background-color: $accent-2;
    color: $white;
    border-radius: $border-radius;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
    font-family: $font-regular;
    padding: 0.7rem 1.5rem;
    font-size: 1.1rem;
    border: 0px;

    &:hover {
        background-color: darken($accent-2, 2%);
        transform: scale(1.03);
    }
}

//Section styles
.stats-section {
    background-color: $neutral-3;
}

.quote-section {
    background-color: $secondary;
}

.testimonial-section {
    background-color: $secondary;
}

.contact-section {
    background-color: $neutral-3;
}

.hire-section {
    background-color: $primary;
}

// Animations

.div-container {
    opacity: 0;
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.div-container.visible {
    opacity: 1;
    transition: opacity 1s ease-out, transform 1s ease-out;
}


.fade-in {
    position: relative;
    animation: mymove 500ms;
    animation-timing-function: ease-in;
}

@keyframes mymove {
    from {
        opacity: 0;
        // transform: translateX(-500px);
    }


    to {
        opacity: 1;
        // transform: translateX(0);
    }
}

.fade-in.visible {
    opacity: 1;
    transition: opacity 1.5s ease-out, transform 1s ease-out;
    z-index: 3;
}

.block-left,
.block-right {
    opacity: 0;
}

/* Add animation */
@keyframes slideInFromLeft {
    from {
        transform: translateX(-30%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Add animation */
@keyframes slideInFromRight {
    from {
        transform: translateX(30%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.block-left.visible {
    opacity: 1;
    // animation: slideInFromLeft 2s forwards;
}

.block-right.visible {
    opacity: 1;
    // animation: slideInFromRight 2s forwards;
    animation: fade-in 2s forwards;
}

.block-left-scroll,
.block-right-scroll {
    opacity: 0;
}

.block-left-scroll.visible {
    opacity: 1;
    animation: fade-in 2s forwards;
}


.block-left-scroll,
.block-right-scroll {
    opacity: 0;
    transition: opacity 0.5s ease-in-out; // Smooth transition
}

.block-left-scroll.visible,
.block-right-scroll.visible {
    opacity: 1;
    animation: fade-in 0.8s ease-in-out forwards;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.block-right-scroll.visible {
    opacity: 1;
    // animation: slideInFromRight 2s forwards;
    animation: fade-in 2s forwards;
}

// Laura Purser Homepage
.contact-form-title {
    margin-top: 20px;
    color: $accent-1;
    text-align: center;
}

.statement-section {
    background-color: $primary;
    padding: 100px;
}

.statement-section-text {
    text-align: left;
    color: $neutral-3;
}

.statement-section-button {
    text-align: center;
}

.client-section {
    background-color: $neutral-3;
    margin-left: 0px;
    margin-right: 0px;
    padding: 60px;
}

.client-section-text {
    color: $secondary;
}

.client-section-icons {
    color: $primary;
}

.client-section-title {
    color: $secondary;
}

.client-section-emphasis {
    color: $accent-1;
    font-family: $font-bold;
}

.client-section-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 40px;
    min-height: 200px;
}

.quote-section-text {
    color: $neutral-3;
}

.quote-section-subtext {
    color: $accent-1;
}

.stats-section-row {
    padding-left: 150px;
    padding-right: 150px;
}

.stats-boxes {
    background-color: $secondary;
    border-radius: 30px;
    padding: 20px;
    min-width: 290px;
    min-height: 370px;
    margin: 10px;
}

.stats-boxes-number {
    font-family: $font-regular;
    color: $accent-1;
    font-size: 100px;
    text-align: center;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.stats-boxes-subheading {
    color: $accent-1;
    text-align: center;
}

.stats-boxes-text {
    color: $neutral-3;
    text-align: center;
    padding: 20px;
    margin-top: 20px;
}

.hire-section-text {
    color: $neutral-3;
}

.hire-section-bullets {
    color: $neutral-3;
}

.hire-section-ticks {
    color: $accent-1;
}

.hire-section-button {
    text-align: left;
}

// Images

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img404 {
    height: 400px;
}

// Media queries

@media (max-width: 1550px) {
    .stats-section-row {
        padding-left: 80px;
        padding-right: 80px;
    }
}

@media (max-width: 1200px) {
    .info-contact {
        width: 90%;
    }

    .contact-form {
        width: 90%;
        margin-top: 20px;
    }

    .homepage-h2 {
        font-size: 28px;
    }

    .statement-section {
        padding: 100px;
    }

    .stats-boxes {
        padding: 20px;
        min-width: 230px;
        min-height: 360px;
        margin: 10px;
    }

    .logo-rect {
        width: 20%;
    }

    .logo-square {
        width: 8%;
    }

}


@media (max-width: 991px) {
    .hero-text-1 {
        font-size: $hero-font-1-md;
    }

    .hero-text-2 {
        font-size: $hero-font-2-md;
    }

    .hero-text-3 {
        font-size: $hero-font-3-md;
    }

    .info-contact {
        width: 88%;
    }

    .contact-form {
        width: 100%;
        margin: 0px;
    }

    .statement-section {
        padding: 90px;
    }

    .flowserve-logo {
        margin-top: 50px;
    }

    .skyline-logo {
        margin-top: 50px;
    }

    .stats-section-row {
        padding-left: 35px;
        padding-right: 35px;
    }

    .logo-rect {
        width: 40%;
    }

    .logo-square {
        width: 15%;
    }
}

@media (max-width: 750px) {
    .hero-text-1 {
        font-size: $hero-font-1-sm;
    }

    .hero-text-2 {
        font-size: $hero-font-2-sm;
    }

    .hero-text-3 {
        font-size: $hero-font-3-sm;
    }

    .info-contact {
        width: 80%;
    }

    .homepage-h2 {
        font-size: 25px;
    }

    .statement-section {
        padding: 50px;
    }

    .client-section {
        padding: 40px;
    }

    .quote-section {
        padding: 50px;
    }

    .stats-section-row {
        padding-left: 10px;
        padding-right: 10px;
    }
}