@use "sass:math";

// Variables / custom Bootstrap overrides
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system
$primary: #225560;
$secondary: #00050a;
$tertiary: #ffc266;
$accent-1: #ffa31a;
$accent-2: #1f81d6;
$accent-3: #66ffff;
$accent-4: #004d4d;
$accent-5: #06171d;
$accent-6: #b3d6ff;
$neutral-1: #111111;
$neutral-2: #00b3b3;
$neutral-3: #ffffff;
$gradiant: #006666;
$white: #fff;

$steel-blue: #1f81d6;

$border-radius: 0px;

//Font family
$font-light: "Ubuntu-Light", Arial, Helvetica, sans-serif;
$font-italic: "Ubuntu-Italic", Arial, Helvetica, sans-serif;
$font-light-italic: "Ubuntu-LightItalic", Arial, Helvetica, sans-serif;
$font-regular: "Ubuntu-Regular", Arial, Helvetica, sans-serif;
$font-medium: "Ubuntu-Medium", Arial, Helvetica, sans-serif;
$font-medium-italic: "Ubuntu-MediumItalic", Arial, Helvetica, sans-serif;
$font-bold: "Ubuntu-Bold", Arial, Helvetica, sans-serif;
$font-bold-italic: "Ubuntu-BoldItalic", Arial, Helvetica, sans-serif;
$font-regular: "Ubuntu-Regular", Arial, Helvetica, sans-serif;

//Font sizes
$hero-font-1-xl: 50px;
$hero-font-2-xl: 32px;
$hero-font-3-xl: 28px;
$hero-font-4-xl: 24px;
$large-text-xl: 20px;
$standard-text-xl: 15px;
$label-text-xl: 15px;

$hero-font-1-lg: 46px;

$hero-font-1-md: 42px;
$hero-font-2-md: 28px;
$hero-font-3-md: 22px;
$hero-font-4-md: 18px;
$standard-text-md: 13px;
$large-text-md: 17px;

$hero-font-1-sm: 30px;
$hero-font-2-sm: 26px;
$hero-font-3-sm: 24px;
$hero-font-4-sm: 18px;
$standard-text-sm: 12px;
$large-text-sm: 16px;
$standard-text-sm: 14px;
$hero-font-1-xs: 24px;

$dark-gray-50: #767676;
$dark-gray-100: #666666;
$dark-gray-200: #575757;
$dark-gray-300: #4b4b4b;
$dark-gray-400: #404040;
$dark-gray-500: #343434;
$dark-gray-600: #232323;
$dark-gray-700: #111111;
$dark-gray-800: #090909;
$dark-gray-900: #000000;


//Blue charcoal color scheme
// #010C19 #071C33 #132E4C #244466 #3B5C7F #577799 #7994B2 #A0B5CC #CDD8E5 #FFFFFF