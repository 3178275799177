@import '../variables';

.contact-reason-block {
    padding-top: 30px;
    margin-top: 60px;
    width: 90%;
}

.contact-hooks {
    text-align: center;
    margin-top: 110px;
}

.contact-icon {
    margin-top: 40px;
    text-align: center;
    margin-bottom: 15px;
    color: $primary;
}

.contact-form {
    padding: 30px;
    margin: 60px 0px 60px 0px;
    width: 80%;
    margin-top: 90px;
    background-color: $accent-5;
}

.contact-subheading-block {
    margin-top: 20px;
    color: $neutral-3;
    text-align: center;
}

.contact-form-fields {
    padding: 40px;
}

.contact-label {
    font-size: $standard-text-xl;
    font-family: $font-regular;
    color: $white;
}

.contact-input {
    width: 100%;
    padding: 10px;
    border: 0px solid $primary;
    color: $neutral-1;
    font-family: $font-regular;
}

.contact-submit {
    text-align: center;
}