@import '../variables';

.hero-container {
    position: relative;
    overflow: hidden;
    padding: 50px 0;
    min-height: calc(100vh - 70px);
    /* background-image: linear-gradient(360deg, #003333 0%, #003333 20%, #006666 60%, #008080 70%, #009999 100%); */
    /* background: linear-gradient(180deg, #006f72, #005a64, #00474c, #00353c); */
    /* background: linear-gradient(180deg, #00aaff, #1c92d2, #26c6da, #ffffff); */
    /* background: linear-gradient(135deg, #00c6ff, #0072ff, #9b59b6, #8e44ad); */
    /* background: linear-gradient(120deg, #ff00cc, #ff6600, #00ccff, #00ff66); */
    /* background: linear-gradient(45deg, #ff0066, #ff6600, #ffcc00, #66cc00, #0099ff, #6600ff, #cc00ff); */
    /* background: linear-gradient(135deg, #1c1c8c, #4531dd, #6f00ff, #ff00ff); */
    background: linear-gradient(180deg,
            $primary,
            #4A7177,
            /* A softened, muted teal that blends better with $primary */
            #42737e,
            /* Smooth transition into a darker teal */
            #355C63,
            /* Even darker teal for consistency */
            #2A4B51,
            /* Darker greenish teal, bridging the gap */
            #1C3A41,
            /* Deep, dark teal */
            #102B31,
            /* Smooth deep teal */
            #06171D,
            /* Very dark teal with subtle depth */
            $secondary);
    z-index: 0;
    /* Ensure it sets the stacking context but doesn't interfere */
}

/* background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cG9seWdvbiBwb2ludHM9IjIwLDMgMzQsMTIgMzQsMjggMjAsMzcgNiwyOCA2LDEyIiBmaWxsPSIjZmZmZmZmIiBmaWxsLW9wYWNpdHk9IjAuNSIgLz48L3N2Zz4='); */
/* background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjAgMjBMNDAgMjBMNDAgNDBMMjAgNDBMMjAgMjBaTTAgMjBMMjAgMjBMMjAgNDBMMCAyMFoiIGZpbGw9IiNmZmZmZmYiIGZpbGwtb3BhY2l0eT0iMC4yIi8+PC9zdmc+'); */
/* background-image: url("data:image/svg+xml,%3Csvg width='96' height='96' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' overflow='hidden'%3E%3Cg transform='translate(-592 -312)'%3E%3Cpath d='M631.346 345 644.077 345 631.377 323C619.62 325.749 609.865 333.912 605.086 345Z'/%3E%3Cpath d='M652.738 352 652.715 352.013 655.083 356.113 667.8 334.1C660.623 326.375 650.551 321.99 640.006 322 638.524 322.003 637.043 322.092 635.571 322.266Z'/%3E%3Cpath d='M651.108 371 676.383 371C679.857 359.493 677.686 347.02 670.528 337.364Z'/%3E%3Cpath d='M648.666 375 635.935 375 648.635 397C660.391 394.251 670.147 386.088 674.925 375Z'/%3E%3Cpath d='M627.273 368 627.297 367.987 624.913 363.859 612.2 385.885C619.377 393.619 629.455 398.01 640.006 398 641.488 397.997 642.969 397.908 644.441 397.734Z'/%3E%3Cpath d='M628.873 349 603.629 349C600.158 360.497 602.322 372.959 609.467 382.612Z'/%3E%3C/g%3E%3C/svg%3E"); */
/* background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjIwIiByPSIxNSIgZmlsbD0iI2ZmZmZmZiIgZmlsbC1vcGFjaXR5PSIwLjUiIC8+PC9zdmc+'); */
/* background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cG9seWdvbiBwb2ludHM9IjIwLDMgMzQsMTIgMzQsMjggMjAsMzcgNiwyOCA2LDEyIiBmaWxsPSIjZmZmZmZmIiBmaWxsLW9wYWNpdHk9IjAuNSIgLz48L3N2Zz4='); */
/* background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48bGluZWFyR3JhZGllbnQgaWQ9ImdyYWRpZW50IiB4MT0iMCIgeTE9IjAiIHgyPSIwIiB5Mj0iMSI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzU2OTgzOSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzFhMmE1MCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxwYXRoIGQ9Ik0xMCwxNSAyMCwyNSAzMCwxNSIgc3Ryb2tlPSJ1cmwoI2dyYWRpZW50KSIgc3Ryb2tlLXdpZHRoPSIzIiBmaWxsPSJub25lIi8+PC9zdmc+'); */
/* background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAsMTUgMjAsMjUgMzAsMTUiIHN0cm9rZT0iI2ZmZmZmZiIgc3Ryb2tlLXdpZHRoPSIzIiBmaWxsPSJub25lIi8+PC9zdmc+'); */
/* background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjE2OSIgaGVpZ2h0PSIxNTk5IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWw6c3BhY2U9InByZXNlcnZlIiBvdmVyZmxvdz0iaGlkZGVuIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDkyIC0xOTIpIj48dGV4dCBmb250LWZhbWlseT0iQ29uc29sYXMsQ29uc29sYXNfTVNGb250U2VydmljZSxzYW5zLXNlcmlmIiBmb250LXdlaWdodD0iNDAwIiBmb250LXNpemU9Ijc2MSIgdHJhbnNmb3JtPSJtYXRyaXgoMSAwIDAgMSA5NDguOTU3IDExMzgpIj4mbHQ7LyZndDs8L3RleHQ+PC9nPjwvc3ZnPg=='); */
/* background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAyIiBoZWlnaHQ9IjQwMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgb3ZlcmZsb3c9ImhpZGRlbiI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ2NiAtMTEyKSI+PHBhdGggZD0iTTU2Ni40NzEgMzgwLjUxMSA1NDguNzA3IDM2Mi43MzkgNjY3LjAyOSAyNDQuNDQyIDc4NS4zMjYgMzYyLjczOSA3NjcuNTYzIDM4MC41MDcgNjY3LjAyOSAyNzkuOTY5IDU2Ni40NzEgMzgwLjUxMVoiLz48L2c+PC9zdmc+'); */
/* PHN2ZyB3aWR0aD0iNjYyIiBoZWlnaHQ9IjMzMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgb3ZlcmZsb3c9ImhpZGRlbiI+PGRlZnM+PGNsaXBQYXRoIGlkPSJjbGlwMCI+PHJlY3QgeD0iMjAzNSIgeT0iMTA3MyIgd2lkdGg9IjY2MiIgaGVpZ2h0PSIzMzAiLz48L2NsaXBQYXRoPjxjbGlwUGF0aCBpZD0iY2xpcDEiPjxyZWN0IHg9IjIzNjciIHk9IjEwNzIiIHdpZHRoPSIzMzEiIGhlaWdodD0iMzMxIi8+PC9jbGlwUGF0aD48Y2xpcFBhdGggaWQ9ImNsaXAyIj48cmVjdCB4PSIyMzY3IiB5PSIxMDcyIiB3aWR0aD0iMzMxIiBoZWlnaHQ9IjMzMSIvPjwvY2xpcFBhdGg+PC9kZWZzPjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMCkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yMDM1IC0xMDczKSI+PGc+PGc+PGc+PHBhdGggZD0iTTIxMTcuNDggMTI5My40MiAyMTAyLjg5IDEyNzguODMgMjIwMC4wMiAxMTgxLjcyIDIyOTcuMTMgMTI3OC44MyAyMjgyLjU1IDEyOTMuNDIgMjIwMC4wMiAxMjEwLjg4IDIxMTcuNDggMTI5My40MloiLz48L2c+PC9nPjwvZz48ZyBjbGlwLXBhdGg9InVybCgjY2xpcDEpIj48ZyBjbGlwLXBhdGg9InVybCgjY2xpcDIpIj48L2c+PC9nPjwvZz48L3N2Zz4= */
.pattern-background {
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjYyIiBoZWlnaHQ9IjMzMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgb3ZlcmZsb3c9ImhpZGRlbiI+PGRlZnM+PGNsaXBQYXRoIGlkPSJjbGlwMCI+PHJlY3QgeD0iMjAzNSIgeT0iMTA3MyIgd2lkdGg9IjY2MiIgaGVpZ2h0PSIzMzAiLz48L2NsaXBQYXRoPjxjbGlwUGF0aCBpZD0iY2xpcDEiPjxyZWN0IHg9IjIzNjciIHk9IjEwNzIiIHdpZHRoPSIzMzEiIGhlaWdodD0iMzMxIi8+PC9jbGlwUGF0aD48Y2xpcFBhdGggaWQ9ImNsaXAyIj48cmVjdCB4PSIyMzY3IiB5PSIxMDcyIiB3aWR0aD0iMzMxIiBoZWlnaHQ9IjMzMSIvPjwvY2xpcFBhdGg+PC9kZWZzPjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMCkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yMDM1IC0xMDczKSI+PGc+PGc+PGc+PHBhdGggZD0iTTIxMTcuNDggMTI5My40MiAyMTAyLjg5IDEyNzguODMgMjIwMC4wMiAxMTgxLjcyIDIyOTcuMTMgMTI3OC44MyAyMjgyLjU1IDEyOTMuNDIgMjIwMC4wMiAxMjEwLjg4IDIxMTcuNDggMTI5My40MloiLz48L2c+PC9nPjwvZz48ZyBjbGlwLXBhdGg9InVybCgjY2xpcDEpIj48ZyBjbGlwLXBhdGg9InVybCgjY2xpcDIpIj48L2c+PC9nPjwvZz48L3N2Zz4=');
    background-size: 164px 82px;
    /* Double the height */
    background-repeat: repeat;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.03;
    pointer-events: none;
    mix-blend-mode: overlay;
}


.hero-content {
    position: relative;
    z-index: 2;
    /* Places content above the pattern background */
    text-align: center;
    padding: 20px;
    height: 100%;
}

/* background-color: rgb(0, 51, 51, 0.4); */

/* Background Layer for Icons */
.icon-background {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
}



/* Float Animation for Icons */
@keyframes float {

    0%,
    100% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-10px);
    }
}


.highlight {
    color: #ffa31a;
    font-family: 'Ubuntu-Bold', 'Arial', sans-serif
        /* Branding Teal */
}


/* Container */
.image-container2 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    text-align: center;
}

.header-style2 {
    color: #fff;
    font-size: 2.2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
}

/* Overlay for Non-Home */
.overlay-style2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
}

.image-style-sub {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
}



/* Responsive Adjustments */
@media (max-width: 768px) {

    .header-style2 {
        font-size: 2rem;
    }

    .cta-button {
        font-size: 0.9rem;
        padding: 0.5rem 1rem;
    }


}

@media (max-width: 480px) {

    .header-style2 {
        font-size: 1.4rem;
    }

    .cta-button {
        padding: 0.4rem 0.8rem;
    }
}