@import "../_variables.scss";

.nav-bg {
  background-color: $white;
}

.logo-style {
  width: 165px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-text {
  font-size: $standard-text-xl !important;
  font-family: $font-regular !important;
  text-decoration: none;
  color: $neutral-1;
  letter-spacing: 1px;
  display: block;
  text-decoration: none;
  padding-top: 7px;
}

.nav-drp-link {
  color: $neutral-1;
  letter-spacing: 1px;
  display: block;
  font-size: $standard-text-xl;
  text-decoration: none;
  border-bottom: 1px solid $white;
  font-family: $font-light;
}

.nav-drp-link:hover {
  color: $neutral-1;
  cursor: pointer;
}

/* Navbar */
.dropdown-menu-left {
  border-radius: 0px !important;
  width: 200px;
  padding: 30px !important;
}

.dropdown-menu-left-wide {
  border-radius: 0px !important;
  width: 270px;
  padding: 30px !important;
}

@media screen and (max-width: 992px) {
  .dropdown-menu-left {
    border: 0px !important;
    padding: 10px !important;
  }

  .dropdown-menu-left-wide {
    border: 0px;
    padding: 10px;
  }
}

@media screen and (min-width: 992px) {
  .xl-nav {
    visibility: visible;
  }

  .sm-nav {
    display: none;
  }
}