/* Ensure the html and body take up the entire viewport */
html,
body {
  height: 100%;
  /* Full height for the page */
  margin: 0;
  /* Remove default margin */
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("assets/fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("assets/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: "Roboto-Light";
  src: url("assets/fonts/Roboto-Light.ttf");
}

@font-face {
  font-family: "Brush-Font";
  src: url("assets/fonts/Warteg-ZV8qz.ttf");
}


@font-face {
  font-family: "Roboto-Regular";
  src: url("assets/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Roboto-Black";
  src: url("assets/fonts/Roboto-Black.ttf");
}

@font-face {
  font-family: "Roboto-Thin";
  src: url("assets/fonts/Roboto-Thin.ttf");
}

@font-face {
  font-family: "Ubuntu-Medium";
  src: url("assets/fonts/Ubuntu-Medium.ttf");
}

@font-face {
  font-family: "Ubuntu-MediumItalic";
  src: url("assets/fonts/Ubuntu-MediumItalic.ttf");
}

@font-face {
  font-family: "Ubuntu-Bold";
  src: url("assets/fonts/Ubuntu-Bold.ttf");
}

@font-face {
  font-family: "Ubuntu-BoldItalic";
  src: url("assets/fonts/Ubuntu-BoldItalic.ttf");
}

@font-face {
  font-family: "Ubuntu-Light";
  src: url("assets/fonts/Ubuntu-Light.ttf");
}

@font-face {
  font-family: "Ubuntu-LightItalic";
  src: url("assets/fonts/Ubuntu-LightItalic.ttf");
}

@font-face {
  font-family: "Ubuntu-Regular";
  src: url("assets/fonts/Ubuntu-Regular.ttf");
}