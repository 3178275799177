.circular--author {
    position: relative;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
}

.circular--author img {
    width: 100%;
    height: auto;
}