@media (max-width: 992px) {
    .hero-text-1 {
        font-size: $hero-font-1-md;
    }

    .hero-text-2 {
        font-size: $hero-font-2-md;
    }

    .container-large {
        padding: 0 2rem;
    }


    .large-text {
        font-size: $large-text-md;
    }

    .standard-text {
        font-size: $standard-text-md;
    }

    .icon-large {
        height: 100px;
        width: 100px;
    }
}