@import '../variables';


.card-header {
    border: 1px solid #d8d8d8 !important;
}

.card .card-header {
    border: 0px solid #d8d8d8 !important;
}

.page-header {
    font-family: "Roboto-Black" !important;
    font-size: 20px;
    letter-spacing: 1px;
    color: $neutral-1 !important;
}

.card-react {
    border-left: 4px solid #898aa6 !important;
}

.card-net {
    border-left: 4px solid #ef7c8e !important;
}

.card-sql {
    border-left: 4px solid #d37f40 !important;
}

.card-json {
    border-left: 4px solid #deb6ab !important;
}

.inputselect {
    font-size: 12px;
    font-family: "Ubuntu-Medium", "Arial" !important;
}

.bg-filter {
    background-color: rgba(231, 243, 243, 0.65);
}


// form fields

.label-text {
    font-family: "Ubuntu-Medium" !important;
    font-size: 12px !important;
    letter-spacing: 1px;
    color: #111 !important;
}

.input-text {
    font-family: "Ubuntu-Medium" !important;
    font-size: 12px !important;
    letter-spacing: 1px;
    color: #111 !important;
}

.input-text-filter {
    font-family: "Ubuntu-Medium" !important;
    font-size: 12px !important;
    letter-spacing: 1px;
    color: #111 !important;
    background-color: #f2f2f2;
    height: 37px;
    border-right: none;
}

.input-text-locked {
    font-family: "Ubuntu-Medium" !important;
    font-size: 12px !important;
    letter-spacing: 1px;
    color: #111 !important;
    background-color: #666666;
}

.loadspinner {
    position: fixed;
    top: 50%;
    left: 50%;
}

.cd-header {
    font-family: "Ubuntu-Medium" !important;
    font-size: 16px;
    letter-spacing: 1px;
    color: #111 !important;
}

.cd-subheader {
    font-family: "Ubuntu-Medium" !important;
    font-size: 14px;
}

.cd-value {
    font-family: "Ubuntu-Medium" !important;
    font-size: 20px;
    letter-spacing: 1px;
    color: #111 !important;
}

.btn-text {
    font-family: "Ubuntu-Medium" !important;
    font-size: 12px !important;
    letter-spacing: 1px;
    color: #fff !important;
}

.btn-text-dark {
    font-family: "Ubuntu-Medium" !important;
    font-size: 12px !important;
    letter-spacing: 1px;
    color: #111 !important;
}

.ag-header-container .ag-header-cell-text,
.ag-theme-balham .ag-pinned-left-header .ag-header-cell-label {
    font-family: $font-regular !important;
    font-size: 13px;
    font-weight: normal;
    color: $dark-gray-50;
    text-align: center !important;
    white-space: nowrap;
}

.ag-theme-balham {
    font-family: $font-medium;
    font-size: 13px;
    color: #111 !important;
}

.bg-btn {
    background-color: #fff !important;
}

.text-lrf {
    font-size: 12px;
    font-family: "Ubuntu-Medium" !important;
    letter-spacing: 1px;
}

.text-filter {
    font-size: 12px !important;
    font-family: "Ubuntu-Medium" !important;
    letter-spacing: 1px;
    height: 38px;
    /* background-color: #007474; */
}

.pc-btn {
    font-family: "Ubuntu-Regular" !important;
    font-size: 11px !important;
    letter-spacing: 1px;
    color: #111 !important;
    background-color: #fff !important;
}

.pc-btn-dark {
    font-family: "Ubuntu-Regular" !important;
    font-size: 11px !important;
    letter-spacing: 1px;
    color: #fff !important;
    background-color: #008080 !important;
}

.pc-btn-success {
    font-family: "Ubuntu-Medium" !important;
    font-size: 11px !important;
    letter-spacing: 1px;
    color: #111 !important;
    background-color: #14a44d !important;
}

.input-file {
    font-family: "Ubuntu-Medium" !important;
    font-size: 13px;
    letter-spacing: 1px;
    color: #111 !important;
}

.b-width {
    width: 100px;
}

// Dashboard lower card borders

.border-left-1 {
    border-left: 0.4rem solid #446c8c !important;
    border-right: 0.1rem solid #d8d8d8 !important;
    border-top: 0.1rem solid #d8d8d8 !important;
    border-bottom: 0.1rem solid #d8d8d8 !important;
}

.border-left-2 {
    border-left: 0.4rem solid #0db2bc !important;
    border-right: 0.1rem solid #d8d8d8 !important;
    border-top: 0.1rem solid #d8d8d8 !important;
    border-bottom: 0.1rem solid #d8d8d8 !important;
}

.border-left-3 {
    border-left: 0.4rem solid #69d2eb !important;
    border-right: 0.1rem solid #d8d8d8 !important;
    border-top: 0.1rem solid #d8d8d8 !important;
    border-bottom: 0.1rem solid #d8d8d8 !important;
}

.border-left-4 {
    border-left: 0.4rem solid #bdd8e1 !important;
    border-right: 0.1rem solid #d8d8d8 !important;
    border-top: 0.1rem solid #d8d8d8 !important;
    border-bottom: 0.1rem solid #d8d8d8 !important;
}

// headers and card headers

.head-text {
    color: #003333;
    font-family: "Ubuntu-Medium";
    font-size: 20px !important;
    letter-spacing: 1px;
}

.sub-text {
    color: #008080;
    font-size: 14px !important;
    /* letter-spacing: 1px; */
}

.card-text {
    color: #111;
    font-family: "Ubuntu-Medium";
    font-size: 20px !important;
    letter-spacing: 1px;
}

.card-sub-text {
    color: #007474;
    font-size: 12px !important;
    letter-spacing: 1px;
    font-family: "Ubuntu-Medium";
}

.card-sub-text-bold {
    color: #111;
    font-size: 16px !important;
    letter-spacing: 1px;
    font-family: "Ubuntu-Bold";
}