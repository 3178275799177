.cookie-banner {
    font-size: 14px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    background-color: #000;
    color: #fff;
    padding: 4px;
    text-align: center;
    z-index: 1000
}

@media (max-width: 775px) {
    .cookie-banner {
        font-size: 12px;
    }
}