/* Footer Styling */
.footer {
    color: white;
    text-align: center;
    flex-shrink: 0;
    /* Prevent the footer from shrinking */
}

/* Footer content styling */
.footer-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* Spread the content across the footer */
    align-items: center;
    /* Vertically center the content */
}

.footer-text {
    font-family: 'Ubuntu-Bold' !important;
    font-size: 13px;
    letter-spacing: 1px;
    color: #111;

}

.footer-brand {
    font-family: 'Ubuntu-Bold' !important;
    font-size: 28px !important;
    letter-spacing: 2px !important;
    color: #008080 !important;
}

.fw-normal {
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 1);
    text-transform: uppercase;
}

.footer-item,
.footer-link {
    color: rgba(0, 0, 0, 0.64) !important;
    font-family: 'Ubuntu-Regular' !important;
    letter-spacing: 1px;
    display: block;
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
}

.base-item {
    color: rgba(0, 0, 0, 1) !important;
    display: block;
    font-size: 12px;
    text-decoration: none;
    letter-spacing: 1px;
}

.footer-link:hover {
    color: rgba(0, 0, 0, 1) !important;
    cursor: pointer;
}