@media (max-width: 768px) {
    .hero-text-1 {
        font-size: $hero-font-1-sm;
    }

    .hero-text-2 {
        font-size: $hero-font-2-sm;
    }

    .container-large {
        padding: 0 1.5rem;
    }

    .contact-form {
        width: 100%;
        padding: 10px;
    }

    .contact-form-fields {
        padding: 20px;
    }

    .standard-text {
        font-size: $standard-text-sm;
    }

    .large-text {
        font-size: $large-text-sm;
    }
}