.customer-section {
    background-color: #fff;
}

.container {
    margin: 0 auto;
    padding: 0 1rem;
    max-width: 1200px;
}

/* Title Styling */
.testimonials-title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 3rem;
    color: #003333;
}

/* Grid Layout for Testimonials */
.testimonials-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
}

@media (min-width: 768px) {
    .testimonials-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

/* Card Styling */
.testimonial-card {
    background-color: #f9f9f9;
    padding: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Header Section (Image and Name) */
.testimonial-header {
    display: flex;
    align-items: center;
}

.testimonial-image {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin-right: 1rem;
}

.testimonial-name {
    font-size: 24px;
    color: #003333;
}

.testimonial-role {
    font-size: 0.875rem;
    color: #6b6b6b;
}

/* Rating Stars */
.testimonial-rating {
    display: flex;
    margin-bottom: 1rem;
}

.star-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: #ffa31a;
    fill: currentColor;
}