@import '../_variables';

.image-container {
  position: relative;
  display: inline-block;
  width: 100%;
  height: auto;
}

.overlay-style {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #06171d;
  opacity: 0.75;
  z-index: 1;
}

/* textonImage.css */

/* Apply animation to h1 and h2 */
.header-style,
.header-button {
  opacity: 1;
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.image-style {
  width: 100%;
  max-height: 550px;
  min-height: 350px;
  z-index: 2;
  object-fit: cover;
}

.image-style-sub {
  width: 100%;
  max-height: 325px;
  min-height: 325px;
  z-index: 2;
  object-fit: cover;
}

.header-style {
  position: absolute;
  top: 245px;
  left: 50px;
  padding: 10px;
  color: #fff;
  z-index: 3;
  letter-spacing: 2px;
  font-family: $font-light;
}

.header-button {
  position: absolute;
  top: 300px;
  left: 50px;
  padding: 10px;
  color: #fff;
  z-index: 3;
}

.header-style-sub {
  position: absolute;
  top: 135px;
  left: 50px;
  padding: 10px;
  z-index: 3;
  letter-spacing: 2px;
}

.subheader-style {
  position: absolute;
  top: 240px;
  left: 50px;
  padding: 10px;
  color: #fff;
  z-index: 3;
  letter-spacing: 2px;
  font-size: 22px;
  font-family: $font-regular;
  opacity: 1;
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.error-style {
  color: #111;
  font-size: 18px;
  letter-spacing: 2px;
  font-family: "Ubuntu-Regular" !important;
}

.banner-header {
  left: 50px;
  padding: 10px;
  color: #fff;
  z-index: 3;
  font-size: 22px;
  letter-spacing: 2px;
  font-family: "Ubuntu-Light" !important;
}

.banner-subheader {
  left: 50px;
  padding: 10px;
  color: #fff;
  z-index: 3;
  font-size: 22px;
  letter-spacing: 2px;
  font-family: "Ubuntu-Regular" !important;
}

@media (max-width: 1200px) {
  .header-style {
    font-size: 32px;
    top: 175px;
  }

  .subheader-style {
    top: 200px;
    font-size: 22px;
  }

  .header-button {
    top: 240px;
  }
}

@media (max-width: 775px) {
  .header-style {
    font-size: 25px;
    left: 10px;
    top: 100px;
  }

  .header-style-sub {
    left: 1rem;
  }

  .subheader-style {
    top: 125px;
    font-size: 18px;
    left: 15px;
  }

  .header-button {
    left: 15px;
    top: 150px;
  }
}

@media (max-width: 650px) {
  .header-style {
    font-size: 5px;
  }

  .header-style-sub {
    font-size: 5px;
    top: 120px;
  }

  .subheader-style {
    top: 110px;
    font-size: 16px;
  }

  .image-style-sub {
    max-height: 275px;
    min-height: 275px;

  }
}