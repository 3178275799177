/* Graphic Container */
.graphic-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 500px;
    background: #1e1e1e;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    font-family: 'Courier New', Courier, monospace;
    overflow: hidden;
    position: relative;
    z-index: 4;

    @media (max-width: 768px) {
        padding: 0.8rem;
        width: 460px;
    }

    @media (max-width: 480px) {
        width: 350px;
    }

    @media (max-width: 400px) {
        width: 350px;
    }
}

/* Editor Body */
.editor-body {
    display: flex;
    padding: 1rem 0;
    width: 440px;

    @media (max-width: 768px) {
        padding: 0.8rem 0;
        width: 400px;
    }

    @media (max-width: 480px) {
        width: 300px;
    }

    @media (max-width: 400px) {
        width: 320px;
    }
}

.line-numbers {
    line-height: 1.4;
    font-size: 1rem;
    text-align: right;
    padding-right: 1rem;
    color: #858585;
    border-right: 1px solid #333;

    @media (max-width: 550px) {
        font-size: 1rem;
    }

    @media (max-width: 480px) {
        font-size: 0.85rem;
    }

    @media (max-width: 400px) {
        font-size: 0.75rem;
        padding-right: 0.5rem;
    }
}

.code-block {
    flex: 1;
    padding-left: 1rem;
    line-height: 1.4 !important;
    font-size: 1rem;
    color: #d4d4d4;
    overflow: hidden;
    white-space: pre;

    @media (max-width: 768px) {
        font-size: 0.9rem;
        padding-left: 0.8rem;
    }

    @media (max-width: 480px) {
        font-size: 0.85rem;
    }

    @media (max-width: 400px) {
        font-size: 0.75rem;
    }
}

.keyword {
    color: #569cd6;
}

.variable {
    color: #9cdcfe;
}

.operator {
    color: #d4d4d4;
}

.string {
    color: #ce9178;
}

.function {
    color: #dcdcaa;
}


.cursor {
    display: inline-block;
    background: #d4d4d4;
    color: transparent;
    width: 8px;
    height: 1em;
    margin-left: 2px;

    @media (max-width: 480px) {
        width: 6px;
        height: 0.8em;
    }

}

.blink {
    animation: blink 1s step-end infinite;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}

.tabs {
    display: flex;
    padding: 0.5rem;
    background: #252526;
    border-radius: 6px 6px 0 0;
    margin-bottom: 0.5rem;


}

.tab {
    padding: 0.5rem 1rem;
    color: #cccccc;
    font-size: 0.9rem;

    @media (max-width: 480px) {
        padding: 0.4rem 0.8rem;
        font-size: 0.85rem;
    }

    @media (max-width: 400px) {
        font-size: 0.75rem;
    }
}

.tab.active {
    background: #1e1e1e;
    color: #fff;
    border-radius: 6px 6px 0 0;
}