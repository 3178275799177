@import '../_variables';

// Old homepage

.section-dark {
  background-color: #003333;
}

.canary-yellow {
  background-color: #fff308;
}

.steel-blue {
  background-color: #1f81d6;
}

.btn-canary-yellow {
  background-color: #fff308;
}

.section {
  margin-left: 20px;
  margin-right: 20px;
}

.section-heading {
  font-size: 28px;
  font-family: "Ubuntu-Medium";
  letter-spacing: 1px;
  color: #111;
}

.section-heading-light {
  font-size: 28px;
  font-family: "Ubuntu-Medium";
  letter-spacing: 1px;
  color: #fff;
}

.section-subheading {
  font-size: 23px;
  /* font-family: 'Ubuntu-re'; */
  letter-spacing: 1px;
  color: #008080;
}

.section-sh-contrast {
  font-size: 23px;
  /* font-family: 'Ubuntu-re'; */
  letter-spacing: 1px;
  color: #00b3b3;
}

.portfolio-item {
  max-width: 25rem;
  margin-left: auto;
  margin-right: auto;
}

.portfolio-item:hover {
  background-color: #003a3a;
  opacity: 0.5;
}

.portfolio-caption {
  padding: 1.5rem;
  text-align: center;
  background-color: #fff;
  max-width: 25rem;
  margin-left: auto;
  margin-right: auto;
}

.portfolio-caption-heading {
  font-size: 22px;
  font-family: "Ubuntu-Medium";
  letter-spacing: 1px;
}

.portfolio-caption-subheading {
  color: #007474;
  font-size: 14px !important;
  font-family: "Ubuntu-Medium";
  /* text-transform: uppercase; */
  letter-spacing: 2px;
}

.portfolio-caption-subheading2 {
  color: #111;
  font-size: 13px !important;
  font-family: "Ubuntu-Medium";
  letter-spacing: 1px;
}